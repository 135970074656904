<template>
  <div id="checkout">
    <Navbar />
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Checkout</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">AIG</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Checkout
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="fade">
      <section class="section" v-if="plan">
        <div class="container">
          <form class="row" v-on:submit.prevent="onSubmit">
            <div class="col-lg-7 col-md-6">
              <div class="rounded shadow-lg p-4">
                <h5 class="mb-4">Billing Details :</h5>
                <UserFields v-bind:$v="$v"/>
              </div>
            </div>
            <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="rounded shadow-lg p-4">

                <h5 class="mb-4">Card Details :</h5>
                <CardFields v-bind:$v="$v"/>
                <hr class="mt-0">
                <div class="d-flex mb-1 justify-content-between">
                  <h5>Summary</h5>
                </div>
                <Summary v-bind:plan="plan"/>
                <PaymentProviders v-model="form.paymentMethod" v-bind:$v="$v"/>
                <div class="mt-4 pt-2">
                  <button class="btn btn-block btn-primary">Place Order</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </transition>
    <Footer />
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>

<script>
  import { ArrowUpIcon } from "vue-feather-icons";
  import Navbar from "@/components/core/Navbar";
  import UserFields from "@/components/checkout/UserFields";
  import CardFields from "@/components/checkout/CardFields";
  import Footer from "@/components/core/Footer";
  import { validationMixin, } from "vuelidate/lib";
  import { required, email, numeric, } from "vuelidate/lib/validators";
  import Summary from "@/components/checkout/Summary";
  import { mapGetters, } from "vuex";
  import PaymentProviders from "@/components/checkout/PaymentProviders";
  export default {
    name: "Checkout",
    mixins: [
      validationMixin,
    ],
    components: {
      Navbar,
      UserFields,
      CardFields,
      Footer,
      ArrowUpIcon,
      Summary,
      PaymentProviders,
    },
    data() {
      return {
        form: {
          user: {
            name       : "",
            lastName   : "",
            companyName: "",
            address    : "",
            city       : "",
            zipCode    : "",
            state      : "",
            country    : "",
            phoneNumber: "",
            email      : "",
          },
          card: {
            cardholder: "",
            number: "",
            date: "",
            cvc: "",
          },
          paymentMethod: null,
        },
      };
    },
    computed: {
      ...mapGetters({ plans: "plans/ordered", }),
      planId() {
        return this.$route.params.id;
      },
      plan() {
        return this.plans.find(plan => plan.id == this.planId);
      },
    },
    validations: {
      form: {
        user: {
          name       : { required, },
          lastName   : { required, },
          companyName: { required, },
          address    : { required, },
          city       : { required, },
          zipCode    : { numeric, },
          state      : { required, },
          country    : { required, },
          phoneNumber: { required, numeric, },
          email      : { required, email, },
        },
        card: {
          cardholder: { required, },
          number: { required, numeric, },
          date: { required, },
          cvc: { required, numeric, },
        },
        paymentMethod: { required, },
      },
    },
    methods: {
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.$swal(
          "Congrats!",
          "Your request has been submitted! Please login yo see the details.",
          "success",
        );
        this.$router.replace("/thank-you");
      }
    },
  };
</script>